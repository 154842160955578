import React, { useState, useEffect } from "react";
import "../styles/loginMarketingDirect.css";
import flag from "../assets/images/pk_testing.svg";
import iraq from "../assets/images/iraq_testing.svg";
import indonesia from "../assets/images/indonesia_testing.svg";
import apple from "../assets/images/app_store_opt.png";
import android from "../assets/images/play_store_opt.png";
import { Button, Input, Spin, Dropdown, Menu, Modal } from "antd";
import {
  userSignup,
  MobilePackage,
  checkNetwork,
  getmsisdn,
  iraqLandingCharge,
  isAuthenticated,
  iraqHECharge,
  iraqHE,
  indonesiaMarketingLogs,
  logUserV2,
  logUser,
} from "../api/requests";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { GlobalOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import MarketPlan from "../components/marketingPlan";
import { Helmet } from "react-helmet";
import NewPkLandingPage from "./NewPkLandingPage";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#FF1E1E" }} spin />
);
// const URL = "https://ip.nf/me.json";

const MarketingDirect = () => {
  const { t, i18n } = useTranslation();

  let x = 0;
  let y = x + 1;
  let { bundle, country, operator, hash } = useParams();
  const [loading, setLoading] = useState(true);
  const [isOtp, setIsOtp] = useState(false);
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [secret, setSecret] = useState("");
  const [redirectTo, setRedirectTo] = useState(false);
  const [payments, setPayments] = useState([]);
  const [lang, setLang] = useState(i18n.language);
  const [packageValue, setPackageValue] = useState("");
  const [resendStatus, setResendStatus] = useState(false);
  const [resendTimer, setResendTimer] = useState(country !== "iq" ? 60 : 300);
  const [redirectLoading, setRedirectLoading] = useState(false);
  const [link, setLink] = useState("");
  const [htmlFunction, setHtmlFunction] = useState("");
  const [scriptSrc, setScriptSrc] = useState("");
  // const [IP, setIP] = useState("");
  const TikTokIntegrationScript = `!(function (w, d, t) {
    w.TiktokAnalyticsObject = t;
    var ttq = (w[t] = w[t] || []);
    (ttq.methods = [
      "page",
      "track",
      "identify",
      "instances",
      "debug",
      "on",
      "off",
      "once",
      "ready",
      "alias",
      "group",
      "enableCookie",
      "disableCookie",
    ]),
      (ttq.setAndDefer = function (t, e) {
        t[e] = function () {
          t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
        };
      });
    for (var i = 0; i < ttq.methods.length; i++)
      ttq.setAndDefer(ttq, ttq.methods[i]);
    (ttq.instance = function (t) {
      for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
        ttq.setAndDefer(e, ttq.methods[n]);
      return e;
    }),
      (ttq.load = function (e, n) {
        var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
        (ttq._i = ttq._i || {}),
          (ttq._i[e] = []),
          (ttq._i[e]._u = i),
          (ttq._t = ttq._t || {}),
          (ttq._t[e] = +new Date()),
          (ttq._o = ttq._o || {}),
          (ttq._o[e] = n || {});
        var o = document.createElement("script");
        (o.type = "text/javascript"),
          (o.async = !0),
          (o.src = i + "?sdkid=" + e + "&lib=" + t);
        var a = document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(o, a);
      });

    ttq.load("CG7FCNRC77UCLSGQPKC0");
    ttq.page();
  })(window, document, "ttq");`;

  const IraqIntegrationScript = `
  !function (w, d, t) {
    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
  
    ttq.load('CHSPLF3C77U2H86LTN80');
    ttq.page();
  }(window, document, 'ttq');
`;

  const IndonesiaIntegrationScript = `!function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

  ttq.load('CI0NPHRC77UBJAEBIDE0');
  ttq.page();
}(window, document, 'ttq');`;

  let tokenCheck = isAuthenticated();

  const jazzTikTokDbLogs = (pageName) => {
    let user = {
      page:
        pageName === 1
          ? "jazz_page_view_tiktok"
          : "jazz_user_subscribed_tiktok",
      // ip: json.ip,
      query: window.location.href,
    };
    console.log("UserPK: ", user);
    logUserV2(user)
      .then((data) => {
        console.log("PK Insertion: ", data);
        if (data.status === "200") {
          console.log("Success hit");
        } else {
          console.log("Failure: ", data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getData = async () => {
    //   const res = await fetch("https://api.ipify.org/?format=json")
    //     .then((res) => res.json())
    //     .then((json) => {
    //       setIP(json.ip);
    let user = {
      page: "loading_landing_page",
      // ip: json.ip,
      query: window.location.href,
    };
    logUserV2(user)
      .then((data) => {
        console.log("IQ Insertion: ", data);
        if (data.status === "200") {
          console.log("Success hit");
          iraqHE().then((data) => {
            if (data.status === 200) {
              console.log("iraq he is :::", data);
              setLink(data.link);
              setHtmlFunction(data.src);
              setScriptSrc(data.script);
              const meta = document.createElement("meta");
              meta.name = "referrer";
              meta.content = "unsafe-url";
              document.head.appendChild(meta);
              var scriptElement = document.createElement("script");
              scriptElement.type = "text/javascript";
              scriptElement.innerHTML = data.script;
              document.head.append(scriptElement);
              var event = new Event("DCBProtectRun");
              document.dispatchEvent(event);
            }
          });
        } else {
          console.log("Failure: ", data);
        }
      })
      .catch((err) => console.log(err));
    // });
  };

  // console.log("LINK::: ", link);

  const sendMarketingLog = async () => {
    console.log("Function CALL: ");

    const apiCall = await fetch(
      `https://fitflexapp.com/checkuser/marketingCallback.php?url=${window.location.href}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log("Smadex Hit: ", json);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (country === "iq") {
      console.log("Inside IQ Page");
      if (window.location.href.indexOf("?") > -1) {
        console.log("HITT ");
        sendMarketingLog();
      }
      getData();
    }
    // if (operator === "jazz") {
    //   if (window.location.href.indexOf("client") > -1) {
    //     jazzTikTokDbLogs(1);
    //   }
    //   getmsisdn()
    //     .then((res) => {
    //       console.log("response: ", res);
    //       if (res !== undefined) {
    //         if (res.status === 200) {
    //           if (res.data !== null) {
    //             let str = res.data;
    //             str = str.substring(1);
    //             setNumber(str);
    //             LoginRedirect(str);
    //           } else {
    //             setRedirectLoading(false);
    //           }
    //         } else {
    //           setRedirectLoading(false);
    //         }
    //       } else {
    //         setRedirectLoading(false);
    //       }
    //     })
    //     .catch((err) => setRedirectLoading(false));
    // }

    let payload = "";

    if (hash !== undefined) {
      payload = {
        country_id: country === "iq" ? 102 : country === "id" ? 100 : 162,
        operator:
          country === "iq" ? "zain" : country === "id" ? "telkomsel" : "jazz",
        language_id: country === "iq" ? 9 : country === "id" ? 8 : 7,
        witskies: hash,
      };
    } else {
      payload = {
        country_id: country === "iq" ? 102 : country === "id" ? 100 : 162,
        operator:
          country === "iq" ? "zain" : country === "id" ? "telkomsel" : "jazz",
        language_id: country === "iq" ? 9 : country === "id" ? 8 : 7,
      };
    }

    MobilePackage(payload)
      .then((data) => {
        if (data.status === 200) {
          console.log('dadadadadad"""" :::', data.data);
          setPayments(data.data);
          setLoading(false);
          setPackageValue(
            bundle === "daily"
              ? data.data[0].package_id
              : bundle === "weekly"
              ? data.data[1].package_id
              : data.data[2].package_id
          );
        }
      })
      .catch((err) => console.log(err));
    if (country === "iq") {
      i18n.changeLanguage("ar");
      setLang("ar");
    } else if (country === "id") {
      i18n.changeLanguage("id");
      setLang("id");
    } else {
      i18n.changeLanguage("en");
      setLang("en");
    }
  }, [bundle]);

  function gtag_report_conversion() {
    window.gtag("event", "conversion", {
      send_to: "AW-10900259174/4sC9CPGm19ADEOaC080o",
      value: 1.0,
      currency: "PKR",
    });
    return false;
  }

  useEffect(() => {
    if (resendStatus) {
      resendTimer > 0 &&
        setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
  }, [resendTimer, resendStatus]);

  function toggleClick(language) {
    setLoading(true);
    i18n.changeLanguage(language);
    setLang(language);

    let payload = {
      country_id: country === "iq" ? 102 : country === "id" ? 100 : 162,
      operator:
        country === "iq" ? "zain" : country === "id" ? "telkomsel" : "jazz",
      language_id: language === "ar" ? 9 : language === "id" ? 8 : 7,
    };

    MobilePackage(payload).then((data) => {
      if (data.status === 200) {
        setPayments(data.data);
        setPackageValue(
          bundle === "daily"
            ? data.data[0].package_id
            : bundle === "weekly"
            ? data.data[1].package_id
            : data.data[2].package_id
        );
      }
      setLoading(false);
    });
  }
  if (bundle === "daily") {
    x = 0;
    y = 1;
  } else if (bundle === "weekly") {
    x = 1;
    y = 2;
  } else if (bundle === "monthly") {
    x = 2;
    y = 3;
  }

  function handleButtonClick(e) {}
  function handleMenuClick(e) {
    e.key === "1"
      ? toggleClick("ar")
      : e.key === "2"
      ? toggleClick("en")
      : e.key === "3"
      ? toggleClick("id")
      : toggleClick("en");
  }
  const menu_pk = (
    <Menu onClick={handleMenuClick} className="menu_item">
      <Menu.Item key="2">English</Menu.Item>
    </Menu>
  );
  const menu_iq = (
    <Menu onClick={handleMenuClick} className="menu_item">
      <Menu.Item key="1">Arabic</Menu.Item>
      <Menu.Item key="2">English</Menu.Item>
    </Menu>
  );
  const menu_id = (
    <Menu onClick={handleMenuClick} className="menu_item">
      <Menu.Item key="2">English</Menu.Item>
      <Menu.Item key="3">Bhasha</Menu.Item>
    </Menu>
  );

  const renderButton = () => {
    return resendTimer <= 0 ? (
      <button
        style={{ cursor: "pointer" }}
        className="resend"
        onClick={() => {
          LoginFunction();
          setResendTimer(country !== "iq" ? 60 : 300);
          setResendStatus(true);
        }}
      >
        Resend OTP
      </button>
    ) : (
      <button className="resend">
        OTP will Expire in {resendTimer} seconds
      </button>
    );
  };
  const renderTime = () => React.Fragment;

  const LoginRedirect = (number) => {
    if (country === "pk") {
      if (number === "") {
        setError(`${t("is_empty")}`);
      } else if (number.substr(0, 2) === "03" && number.length === "11") {
        setError("Invalid entry. Required Pattern : +92300-0000000");
      } else if (
        number.substr(0, 1) === "3" &&
        number.length === "10" &&
        number.match(/^[0-9]+$/)
      ) {
        setLoading(true);
        setError("");

        let netCheck = {
          msisdn: number,
        };

        checkNetwork(tokenCheck, netCheck).then((data) => {
          if (data !== undefined) {
            if (data.status === 200) {
              if (
                data.data.network_type === "postpaid" &&
                bundle !== "monthly"
              ) {
                setLoading(false);
                Modal.confirm({
                  title: "Information",
                  icon: <ExclamationCircleOutlined />,
                  content: "This package is only for prepaid customers",
                  okText: "Ok",
                  centered: true,
                  cancelText: () => null,
                  closable: true,
                });
                setRedirectLoading(false);
                return;
              } else {
                const user = {
                  msisdn: "92" + number,
                };
                userSignup(user).then((data) => {
                  if (data.message === "error") {
                    setError(data.data);
                    setLoading(false);
                    setRedirectLoading(false);
                  } else {
                    setResendStatus(true);
                    setLoading(false);
                    setRedirectLoading(false);
                    setError("");
                    setSecret(data.data);
                    setIsOtp(true);
                  }
                });
              }
            }
          }
        });
      } else {
        setError(`${t("invalid_pattern")}`);
      }
    } else if (country === "iq") {
      if (number === "") {
        setError(`${t("is_empty")}`);
      } else if (number.substr(0, 3) === "964" && number.length === "10") {
        setError("Invalid entry. Required Pattern : +964-XXXXXXXXXX");
      } else if (number.length === "10" && number.match(/^[0-9]+$/)) {
        setLoading(true);
        setError("");

        let iraqPayload = {
          msisdn: "964" + number,
          package_id: packageValue,
          country_id: 102,
        };
        iraqLandingCharge(iraqPayload).then((data) => {
          if (data.status === 200) {
            setResendStatus(true);
            setLoading(false);
            setRedirectLoading(false);
            setError("");
            setIsOtp(true);
          } else {
            setLoading(false);
            setRedirectLoading(false);
            setError("Could not login at the moment, please try again later.");
          }
        });
      } else {
        setError(`${t("invalid_pattern")}`);
      }
    } else if (country === "id") {
      if (number === "") {
        setError("Field is empty. Enter a valid number.");
      } else if (number.length < 11) {
        setError("Invalid entry");
      } else if (number.length === 11 && number.match(/^[0-9]+$/)) {
        setLoading(true);
        setError("");
        let indonesiaPayload = {
          msisdn: "62" + number,
          package_id: packageValue,
          country_id: 100,
        };
        iraqLandingCharge(indonesiaPayload).then((data) => {
          if (data.status === 200) {
            setResendStatus(true);
            setLoading(false);
            setRedirectLoading(false);
            setError("");
            setIsOtp(true);
          } else {
            setLoading(false);
            setRedirectLoading(false);
            setError("Could not login at the moment, please try again later.");
          }
        });
      }
    }
  };

  const LoginFunction = () => {
    if (country === "pk") {
      if (number === "") {
        setError(`${t("is_empty")}`);
      } else if (number.substr(0, 2) === "03" && number.length === "11") {
        setError("Invalid entry. Required Pattern : +92300-0000000");
      } else if (
        number.substr(0, 1) === "3" &&
        number.length === "10" &&
        number.match(/^[0-9]+$/)
      ) {
        setLoading(true);
        setError("");

        let netCheck = {
          msisdn: number,
        };

        checkNetwork(tokenCheck, netCheck).then((data) => {
          if (data !== undefined) {
            if (data.status === 200) {
              if (
                data.data.network_type === "postpaid" &&
                bundle !== "monthly"
              ) {
                setLoading(false);
                Modal.confirm({
                  title: "Information",
                  icon: <ExclamationCircleOutlined />,
                  content: "This package is only for prepaid customers",
                  okText: "Ok",
                  centered: true,
                  cancelText: () => null,
                  closable: true,
                });
                return;
              } else {
                let pakistanPayload = {
                  msisdn: "92" + number,
                  package_id: packageValue,
                  country_id: 162,
                };
                iraqLandingCharge(pakistanPayload).then((data) => {
                  console.log("My Payloadss", data);
                  if (data.status === 200) {
                    // if (data.data.status === 200) {
                    setResendStatus(true);
                    setLoading(false);
                    setError("");
                    setIsOtp(true);
                    // } else {
                    //   setLoading(false);
                    //   setError(data.data.msg);
                    // }
                  } else {
                    setLoading(false);
                    setError(data.message);
                  }
                });
              }
            }
          }
        });
      } else {
        setError(`${t("invalid_pattern")}`);
      }
    } else if (country === "iq") {
      setLoading(true);
      setError("");

      let iraqPayload = {
        msisdn: "9641234567891",
        package_id: packageValue,
        country_id: 102,
        otp: "12345",
      };
      iraqHECharge(iraqPayload).then((data) => {
        setLoading(false);
        setError("Package is subscribed succesfully");
        gtag_report_conversion();
        setTimeout(() => {
          setRedirectTo(true);
        }, 1000);
      });
    } else if (country === "id") {
      if (number === "") {
        setError("Field is empty. Enter a valid number.");
      } else if (number.length < 11) {
        setError("Invalid entry");
      } else if (number.length === 11 && number.match(/^[0-9]+$/)) {
        setLoading(true);
        setError("");
        let indonesiaPayload = {
          msisdn: "62" + number,
          package_id: packageValue,
          country_id: 100,
        };
        iraqLandingCharge(indonesiaPayload).then((data) => {
          if (data.status === 200) {
            setResendStatus(true);
            setLoading(false);
            setError("");
            setIsOtp(true);
          } else {
            setLoading(false);
            setError("Could not login at the moment, please try again later.");
          }
        });
      }
    }
  };

  const onSubmit = () => {
    if (country === "pk") {
      if (OTP.length === 0) {
        return;
      }
      if (OTP.length !== 4) {
        setError(`${t("four_digits")}`);
      } else {
        setLoading(true);
        let otpPayload = {
          msisdn: "92" + number,
          package_id: packageValue,
          country_id: 162,
          otp: OTP,
          marketPartner: "web",
        };

        iraqLandingCharge(otpPayload)
          .then((data) => {
            console.log("response data: ", data);
            if (data.status === 200) {
              // if (data.data.status === 200) {
              setLoading(false);
              setError("Package is subscribed succesfully");
              window.ttq.track("Subscribe");
              window.gtag("event", "conversion", {
                send_to: "AW-10779246142/KIukCMWj7OMYEL78-JMo",
              });
              window.gtag("event", "conversion", {
                send_to: "AW-10779246142/VrNhCM_3t-gYEL78-JMo",
              });
              if (bundle === "daily") {
                window.gtag("event", "conversion", {
                  send_to: "AW-10779246142/WUnCCPbawc8DEL78-JMo",
                });
              } else if (bundle === "weekly") {
                window.gtag("event", "conversion", {
                  send_to: "AW-10779246142/MJkbCMTqwc8DEL78-JMo",
                });
              } else if (bundle === "monthly") {
                window.gtag("event", "conversion", {
                  send_to: "AW-10779246142/T4jyCJ779s8DEL78-JMo",
                });
              }
              if (window.location.href.indexOf("client") > -1) {
                jazzTikTokDbLogs(2);
              }
              setTimeout(() => {
                window.ttq.track("AddToWishlist");
                setRedirectTo(true);
              }, 1000);
              // } else {
              //   setLoading(false);
              //   setError(data.data.msg);
              // }
            } else {
              setLoading(false);
              setError(data.message);
            }
          })
          .catch((err) => {
            setError("Invalid OTP");
            setLoading(false);
          });
      }
    } else if (country === "iq") {
      setLoading(true);
      let otpPayload = {
        msisdn: "964" + number,
        package_id: packageValue,
        country_id: 102,
        otp: OTP,
        marketPartner: "web",
      };

      iraqLandingCharge(otpPayload)
        .then((data) => {
          if (data.status === 200) {
            setLoading(false);
            setError("Package is subscribed succesfully");
            gtag_report_conversion();
            setTimeout(() => {
              setRedirectTo(true);
            }, 1000);
          } else {
            setLoading(false);
            setError("Invalid OTP");
          }
        })
        .catch((err) => {
          setError("Invalid OTP");
          setLoading(false);
        });
    } else if (country === "id") {
      if (OTP.length === 0) {
        return;
      }
      if (OTP.length !== 4) {
        setError("Invalid Entry, try again");
      } else {
        setLoading(true);
        let otpPayload = {
          msisdn: "62" + number,
          package_id: packageValue,
          country_id: 100,
          otp: OTP,
          marketPartner: "web",
        };

        iraqLandingCharge(otpPayload)
          .then((data) => {
            console.log("ID: ", data);
            if (data.status === 200) {
              if (data.data.status === 200) {
                console.log("ID DATA: ", data);

                window.ttq.track("Subscribe");
                setError("Package is subscribed succesfully");
                let logsPayload = {
                  msisdn: "62" + number,
                  package_id: packageValue,
                  country_id: 100,
                  otp: OTP,
                  MarketingUrl: window.location.href,
                };

                console.log("LOGS PAYLOAD: ", logsPayload);

                indonesiaMarketingLogs(logsPayload)
                  .then((data) => {
                    setLoading(false);
                    console.log("LOGS API: ", data);
                    // setTimeout(() => {
                    //   setRedirectTo(true);
                    // }, 1000);
                  })
                  .catch((err) => {
                    setLoading(false);
                    // setTimeout(() => {
                    //   setRedirectTo(true);
                    // }, 1000);
                  });
              } else {
                setLoading(false);
                setError("Invalid OTP");
              }
            } else {
              setLoading(false);
              setError("Invalid OTP");
            }
          })
          .catch((err) => {
            setError("Invalid OTP");
            setLoading(false);
          });
      }
    }
  };

  if (redirectTo) {
    window.location.replace("https://fitflexapp.com/checkuserlp");
  }

  const iraqRedirection = () => {
    setLoading(true);
    // window.location.replace(
    //   "http://www.social-sms.com/iq-dcb/HE/v1.1/doubleclick/sub.php?serviceId=283&spId=98&shortcode=4089"
    // "http://www.social-sms.com/iq-dcb/HE/v1.2/oneclick/sub.php?serviceId=283&spId=98&shortcode=4089"

    // );
    let user = {
      page: "iraq_user_redirect_btn",
      // ip: IP,
      query: window.location.href,
      // uniqid: "iraq_connect",
    };
    logUser(user)
      .then((data) => {
        if (data.status === 200) {
          console.log("iraq user logged ::::", data);
          window.location.replace(link);
        } else {
          window.location.replace(link);
        }
      })
      .catch((err) => window.location.replace(link));
  };

  if (redirectLoading) {
    return (
      <div className="loader">
        <Spin
          className="spinner"
          indicator={antIcon}
          tip="Fetching Details..."
        />
      </div>
    );
  }

  return (
    <>
      {country === "pk" ? (
        <NewPkLandingPage
          country={country}
          operator={operator}
          bundle={bundle}
        />
      ) : (
        <div className="Login-page-containerMk">
          {bundle === "daily" && country === "iq" && operator === "zain" && (
            <Helmet>
              {/* <meta name="referrer" content="unsafe-url" /> */}
              {/* <script async="" crossorigin="anonymous" src={scriptSrc} /> */}
              {/* <script type="text/javascript">{scriptSrc}</script>
              <script>{IraqIntegrationScript}</script> */}
            </Helmet>
          )}

          {/* {bundle === "daily" && country === "pk" && operator === "jazz" && (
        <Helmet>
          <script>{TikTokIntegrationScript}</script>
        </Helmet>
      )} */}

          {bundle === "daily" &&
            country === "id" &&
            operator === "telkomsel" && (
              <Helmet>
                <script>{IndonesiaIntegrationScript}</script>
              </Helmet>
            )}

          <div className="containerLeftMk">
            <div className="termsMk">
              {
                payments.length > 0 &&
                  payments
                    .map((item, index) => {
                      return (
                        <div className="marketCardMk" key={index}>
                          <MarketPlan
                            hash={hash}
                            rate={
                              t(item.package_name) +
                              ` ${
                                country === "iq"
                                  ? "Iqd. "
                                  : country === "id"
                                  ? "Rp. "
                                  : "Rs. "
                              } ` +
                              item.package_rate
                            }
                            duration={item.package_description}
                            description={
                              hash !== undefined
                                ? "شاهد الآن تمارين وفيديو تدريب غير محدود"
                                : // : t("watch_unlimited")
                                country === "id"
                                ? ""
                                : "No Charges During Free Trial"
                            }
                            term={item.terms_and_conditions}
                          />
                        </div>
                      );
                    })
                    .slice(x, y)
                // )
              }
            </div>
          </div>

          <div className="containerRightMk">
            <div className="titleDivMk">
              <div style={{ display: "inline-block" }}>
                <span className="titletextMk" style={{ color: "#ff1e1e" }}>
                  FIT
                </span>
                <span className="titletextMk" style={{ color: "#ff1e1e" }}>
                  FLEX
                </span>
              </div>
              {hash === undefined && (
                <span className="myDropdownMk">
                  {country === "iq" ? (
                    <Dropdown overlay={menu_iq}>
                      <a className="dropdown" onClick={handleButtonClick}>
                        {lang.toUpperCase()}
                        <GlobalOutlined
                          style={{ color: "#ff1e1e", marginLeft: "20px" }}
                        />
                      </a>
                    </Dropdown>
                  ) : country === "id" ? (
                    <Dropdown overlay={menu_id}>
                      <a className="dropdown" onClick={handleButtonClick}>
                        {lang.toUpperCase()}
                        <GlobalOutlined
                          style={{ color: "#ff1e1e", marginLeft: "20px" }}
                        />
                      </a>
                    </Dropdown>
                  ) : (
                    <Dropdown overlay={menu_pk}>
                      <a className="dropdown" onClick={handleButtonClick}>
                        {lang.toUpperCase()}
                        <GlobalOutlined
                          style={{ color: "#ff1e1e", marginLeft: "20px" }}
                        />
                      </a>
                    </Dropdown>
                  )}
                </span>
              )}
            </div>

            {isOtp ? (
              <>
                <h1
                  className={lang === "ar" ? "welcome_arabicMk" : "welcomeMk"}
                >
                  {hash !== undefined ? "OTP أدخل" : t("enter_otp")}
                </h1>
                <p className={lang === "arMk" ? "ttext_arabicMk" : "ttextMk"}>
                  <ResendOTP
                    renderButton={renderButton}
                    renderTime={renderTime}
                    onResendClick={() => console.log("Resend clicked")}
                  />
                </p>
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus={false}
                  OTPLength={country === "iq" ? 5 : 4}
                  otpType="number"
                  disabled={false}
                  // secure
                  inputClassName="otpinputMk"
                  className="otpMk"
                />
                <p
                  className="ttextMk"
                  style={{
                    color: "red",
                    marginTop: 20,
                    marginBottom: 0,
                    display: error != "" ? "flex" : "none",
                  }}
                >
                  {error}
                </p>
                {loading ? (
                  <div className="loading-div">
                    <Spin indicator={antIcon} tip="Verifying OTP..." />
                  </div>
                ) : (
                  <Button
                    className={
                      lang === "ar" ? "btn-login_arabicMk" : "btn-loginMk"
                    }
                    onClick={onSubmit}
                  >
                    {hash !== undefined ? "جاري" : t("Click to continue")}
                  </Button>
                )}
              </>
            ) : (
              <>
                {operator === "zain" && country === "iq" ? (
                  <h1
                    style={{
                      fontSize: lang === "ar" && 25,
                    }}
                    className={lang === "ar" ? "welcome_arabicMk" : "welcomeMk"}
                  >
                    {window.location.href.indexOf("ff") > -1
                      ? bundle === "daily"
                        ? t("start_your_one_day_free_trial")
                        : t("start_your_three_days_free_trail")
                      : t("start_your_free_trail")}
                  </h1>
                ) : (
                  <h1
                    className={lang === "ar" ? "welcome_arabicMk" : "welcomeMk"}
                  >
                    {hash !== undefined
                      ? "أهلا بعودتك"
                      : country === "id"
                      ? t("welcome_back")
                      : "Start 3-Day Free Trial Today!"}
                  </h1>
                )}

                <p
                  style={{
                    display: operator === "zain" && country === "iq" && "none",
                  }}
                  className={lang === "arMk" ? "ttext_arabicMk" : "ttextMk"}
                >
                  {hash !== undefined
                    ? "سنرسل لك رمز التحقق عبر رسالة. قد يتم تطبيق رسوم البيانات والرسائل"
                    : // : t("text_you")
                    country === "id"
                    ? t("text_you")
                    : " Enter Your Jazz Mobile Number"}
                </p>
                <div
                  style={{
                    display: operator === "zain" && country === "iq" && "none",
                  }}
                  className={
                    lang === "arMk" ? "inputdiv_arabicMk" : "inputdivMk"
                  }
                >
                  <img
                    src={
                      country === "iq"
                        ? iraq
                        : country === "id"
                        ? indonesia
                        : country === "pk"
                        ? flag
                        : flag
                    }
                    className="flagstyle"
                    alt="flag"
                    style={{ width: "10%", height: "100%" }}
                  />
                  <Input
                    className="inputfeildMk"
                    type="number"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="XXXXXXXXXX"
                    bordered={false}
                    prefix={
                      <span
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {country === "iq"
                          ? "+964"
                          : country === "pk"
                          ? " +92"
                          : country === "id"
                          ? "+62"
                          : "+92"}
                      </span>
                    }
                  />
                </div>
                <p
                  className="ttextMk"
                  style={{
                    color: "red",
                    marginTop: 20,
                    marginBottom: 0,
                    display: error != "" ? "flex" : "none",
                  }}
                >
                  {error}
                </p>
                {loading ? (
                  <div className="loading-div">
                    <Spin indicator={antIcon} tip="Loading..." />
                  </div>
                ) : operator === "zain" && country === "iq" ? (
                  // link === "" ? (
                  //   <div className="loading-div">
                  //     <Spin indicator={antIcon} tip="Loading..." />
                  //   </div>
                  // )
                  <Button
                    className={
                      lang === "arMk" ? "btn-login_arabicMk" : "btn-loginMk"
                    }
                    id="subscribe_btn"
                    onClick={iraqRedirection}
                  >
                    {t("click_to_continue")}
                  </Button>
                ) : (
                  <Button
                    className={
                      lang === "arMk" ? "btn-login_arabicMk" : "btn-loginMk"
                    }
                    onClick={LoginFunction}
                  >
                    {hash !== undefined ? "جاري" : t("Click to continue")}
                  </Button>
                )}
              </>
            )}

            {/* <div className="social-iconsMk">
          <a href="https://www.facebook.com/Fitflex-108023608383081/">
            <img src={Facebook} style={{ marginRight: 50 }} />
          </a>
          <a href="https://www.instagram.com/fitflex_app/">
            <img src={Instagram} style={{ marginRight: 50 }} />
          </a>
          <a href="https://www.youtube.com/channel/UCajFgus4QBtdyA0MCTDSUZg">
            <img src={youtube} className="icon-spacing" />
          </a>
        </div> */}
            <div className="margin-top-minus-on-smallMk">
              <a
                href="https://play.google.com/store/apps/details?id=fitflex.workout.fitness.weight.gym.fat.training"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={android}
                  style={{
                    marginRight: "5px",
                    width: "100%",
                    height: "100%",
                  }}
                  alt="playstore"
                  className="storeimg"
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/fitflex/id1613514782"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={apple}
                  style={{
                    border: "1px solid grey",
                    borderRadius: "4px",
                    width: "100%",
                    height: "100%",
                  }}
                  alt="appstore"
                  className="storeimg"
                />
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MarketingDirect;
